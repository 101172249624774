import React from 'react';
import { useState, useEffect, lazy, Suspense, useCallback } from "react";
import { BrowserRouter as Router, Route, Routes, useLocation, Navigate } from "react-router-dom";
import { HelmetProvider } from "react-helmet-async";
import ScrollToTop from "./ScrollToTop";
import SEO from "./Pages/helper/SEO";
import { fetchSEOData } from "./Pages/helper/fetchSEOData";
import { SourceUrlProvider } from './context/SourceUrlContext';
import "./App.css";
import "./external.css";
import "animate.css/animate.min.css";
import "./custom.css";
import "./landing.css"
import "./responsive.css";
import { WOW } from 'wowjs';

// Lazy load components
const Home = lazy(() => import("./Pages/Home/Home"));
const About = lazy(() => import("./Pages/About/About"));
const Faq = lazy(() => import("./Pages/Faq/Faq"));
const Project = lazy(() => import("./Pages/Project/Project"));
const ProjectDetail = lazy(() => import("./Pages/Project/ProjectDetail"));
const Media = lazy(() => import("./Pages/Media/Media"));
const Services = lazy(() => import("./Pages/Services/Services"));
const ServicesListing = lazy(() => import("./Pages/Services/ServiceListing"));
const Contact = lazy(() => import("./Pages/Contact/Contact"));
const Career = lazy(() => import("./Pages/Career/Career"));
const CareerDetail = lazy(() => import("./Pages/Career/CareerDetail"));
const BlogListing = lazy(() => import("./Pages/blogListing/BlogListing"));
const BlogDetail = lazy(() => import("./Pages/blogListing/BlogDetail"));
const Thankyou = lazy(() => import("./Pages/Thankyou/Thankyou"));
const NotFound = lazy(() => import("./Pages/NotFound/NotFound"));
const Layout = lazy(() => import("./Layout/Layout"));
const ServiceLanding = lazy(() => import("./Pages/ServiceLanding/ServiceLanding"));
const MainLanding = lazy(() => import("./Pages/MainLanding/MainLanding"));
const CategoryLanding = lazy(() => import("./Pages/CategoryLanding/CategoryLanding"));
const PrivacyPolicy = lazy(() => import("./Pages/PrivacyPolicy/PrivacyPolicy"));
const TermsConditions = lazy(() => import("./Pages/TermsConditions/TermsConditions"));

function useSEOData(pathname) {
  const [seoData, setSeoData] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      const data = await fetchSEOData(pathname);
      setSeoData(data);
    };
    fetchData();
  }, [pathname]);

  return seoData;
}

const NormalizeTrailingSlash = () => {
  const location = useLocation();

  useEffect(() => {
    if (location.pathname !== '/' && location.pathname.endsWith('/')) {
      window.history.replaceState(
        null,
        '',
        location.pathname.slice(0, -1) + location.search + location.hash
      );
    }
  }, [location]);

  return null;
};

const AppContent = React.memo(() => {
  const location = useLocation();
  const seoData = useSEOData(location.pathname);

  // Store referrer or current URL in sessionStorage
  // useEffect(() => {
  //   const sessionCheck = sessionStorage.getItem("sourceUrlSession");
  //   console.log(sessionCheck, "source url")
  //   if (!sessionCheck) {
  //     const referrer = document.referrer || window.location.href;
  //     sessionStorage.setItem("sourceUrlSession", referrer);
  //   }
  // }, []);

  useEffect(() => {
    const wow = new WOW();
    wow.init();
    return () => wow.sync();
  }, []);

  const renderFallback = useCallback(
    () => <div className="loading"></div>,
    []
  );

  return (
    
    <HelmetProvider>

      <SEO seo={seoData} />
      <NormalizeTrailingSlash />
      <Suspense fallback={renderFallback()}>
        <Layout>
          <ScrollToTop />
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/about-us" element={<About />} />
            <Route path="/faq" element={<Faq />} />
            <Route path="/project" element={<Project />} />
            <Route path="/project/:slug" element={<ProjectDetail />} />
            <Route path="/media" element={<Media />} />
            <Route path="/:slug" element={<Services />} />
            <Route path="/service-listing" element={<ServicesListing />} />
            <Route path="/contact-us" element={<Contact />} />
            <Route path="/career" element={<Career />} />
            <Route path="/blogs" element={<BlogListing />} />
            <Route path="/blogs/:category/:slug" element={<BlogDetail />} />
            <Route path="/career/:slug" element={<CareerDetail />} />
            <Route path="/thankyou" element={<Thankyou />} />
            <Route path="/something-went-wrong" element={<NotFound />} />
            <Route path="*" element={<NotFound />} />
            <Route path="/luxury-interior-design-company" element={<ServiceLanding />} />
            <Route path="/interior-designer-in-gurgaon" element={<MainLanding />} />
            <Route path="/residential-interior-design" element={<CategoryLanding />} />
            <Route path="/privacy-policy" element={<PrivacyPolicy />} />
            <Route path="/terms-conditions" element={<TermsConditions />} />

           {/* //redirects */}
           <Route
        path="/interiors"
        element={<Navigate to="/luxury-interior-design-company" replace />}
      />
      <Route
        path="/residential-interior-designers-in-south-delhi"
        element={<Navigate to="/interior-designers-in-south-delhi" replace />}
      />
      <Route
        path="/interior-design-studio-in-gurgaon"
        element={<Navigate to="/interior-designer-gurgaon" replace />}
      />
      <Route
        path="/wardrobe-designs-gurgaon"
        element={<Navigate to="/interior-designer-gurgaon" replace />}
      />
      <Route
        path="/office-interior-designers-vasant-kunj"
        element={<Navigate to="/luxury-interior-designers-in-vasant-kunj" replace />}
      />
      <Route
        path="/pioneer-araya-luxury-interiors-in-gurgaon"
        element={<Navigate to="/interior-designer-gurgaon" replace />}
      />
      <Route
        path="/ireo-skyon-interior-designs-in-gurgaon"
        element={
          <Navigate
            to="/project/ireo-skyon-sector-60-gurugram-interior-designer"
            replace
          />
        }
      />
      <Route
        path="/best-interior-designers-in-vasant-kunj"
        element={<Navigate to="/luxury-interior-designers-in-vasant-kunj" replace />}
      />
      <Route
        path="/pioneer-araya-interior-designs-in-gurgaon"
        element={<Navigate to="/interior-designer-gurgaon" replace />}
      />
      <Route
        path="/bestech-park-view-spa-luxury-interiors-in-gurgaon"
        element={<Navigate to="/interior-designer-gurgaon" replace />}
      />
      <Route
        path="/dlf-the-magnolias-luxury-interiors-in-gurgaon"
        element={
          <Navigate
            to="/project/dlf-the-magnolias-gurgaon-luxury-interior-designers"
            replace
          />
        }
      />
      <Route
        path="/vipul-tatvam-villas-luxury-interiors-in-gurgaon"
        element={<Navigate to="/interior-designer-gurgaon" replace />}
      />
      <Route
        path="/m3m-golf-estate-interiors-in-gurgaon"
        element={<Navigate to="/interior-designer-gurgaon" replace />}
      />
      <Route
        path="/residential-interior-designers-vasant-kunj"
        element={<Navigate to="/luxury-interior-designers-in-vasant-kunj" replace />}
      />
      <Route
        path="/office-interior-designers-in-greater-kailash"
        element={<Navigate to="/interior-designers-in-greater-kailash" replace />}
      />
      <Route
        path="/m3m-golf-estate-luxury-interiors-in-gurgaon"
        element={<Navigate to="/interior-designer-gurgaon" replace />}
      />
      <Route
        path="/residential-interior-designers-in-greater-kailash"
        element={<Navigate to="/interior-designers-in-greater-kailash" replace />}
      />
      <Route
        path="/dlf-the-crest-interior-design-ideas-in-gurgaon"
        element={
          <Navigate
            to="/project/dlf-the-crest-gurgaon-dlf-phase-5-interior-designers"
            replace
          />
        }
      />
      <Route
        path="/interior-designers-vasant-kunj"
        element={<Navigate to="/luxury-interior-designers-in-vasant-kunj" replace />}
      />
      <Route
        path="/vipul-tatvam-villas-interior-designs-in-gurgaon"
        element={<Navigate to="/interior-designer-gurgaon" replace />}
      />
      <Route
        path="/central-park-2-resorts-interior-designs-in-gurgaon"
        element={<Navigate to="/interior-designer-gurgaon" replace />}
      />
      <Route
        path="/luxury-interior-designers-in-hauz-khas"
        element={
          <Navigate
            to="/residential-interior-designers-hauz-khas-delhi"
            replace
          />
        }
      />
      <Route
        path="/ireo-skyon-luxury-interiors-in-gurgaon"
        element={
          <Navigate
            to="/project/ireo-skyon-sector-60-gurugram-interior-designer"
            replace
          />
        }
      />
      <Route
        path="/bestech-park-view-spa-interior-in-gurgaon"
        element={<Navigate to="/interior-designer-gurgaon" replace />}
      />
      <Route
        path="/dlf-the-crest-luxury-interiors-in-gurgaon"
        element={
          <Navigate
            to="/project/dlf-the-crest-gurgaon-dlf-phase-5-interior-designers"
            replace
          />
        }
      />
      <Route
        path="/dlf-the-crest-interior-designs-in-gurgaon"
        element={
          <Navigate
            to="/project/dlf-the-crest-gurgaon-dlf-phase-5-interior-designers"
            replace
          />
        }
      />
      <Route
        path="/luxury-interior-designers-in-greater-kailash"
        element={<Navigate to="/interior-designers-in-greater-kailash" replace />}
      />
      <Route
        path="/ambience-creacions-interior-designs-in-gurgaon"
        element={<Navigate to="/interior-designer-gurgaon" replace />}
      />
      <Route
        path="/blogs/decor-ideas/the-magic-touch-of-the-best-interior-designers-in-lucknow"
        element={<Navigate to="/interior-designers-in-lucknow" replace />}
      />
      <Route
        path="/blogs/decor-ideas/how-to-choose-the-best-residential-interior-designer-in-delhi"
        element={<Navigate to="/blogs/decor-ideas/a-guide-to-residential-interiors-in-delhi" replace />}
      />
      <Route
        path="/blogs/innovations/home-interior-designer-in-lucknow"
        element={<Navigate to="/interior-designers-in-lucknow" replace />}
      />
      <Route
        path="/blogs/innovations/interior-design-practices-in-delhi-homes"
        element={
          <Navigate
            to="/blogs/decor-ideas/how-to-achieve-a-perfect-balance-of-luxury-and-functionality-in-your-delhi-home"
            replace
          />
        }
      />
      <Route
        path="/blogs/decor-ideas/the-latest-trends-in-interior-design-insights-from-agra-s-experts"
        element={<Navigate to="/interior-designers-in-agra" replace />}
      />
      <Route
        path="/blogs/innovations/how-the-best-interior-designers-in-india-are-redefining-modern-living"
        element={<Navigate to="/top-interior-designers-in-india" replace />}
      />
      <Route
        path="/blogs/innovations/interior-decorator-service-for-home"
        element={
          <Navigate
            to="/blogs/innovations/the-benefits-of-choosing-a-full-service-interior-design-company-for-luxury-homes"
            replace
          />
        }
      />
      <Route
        path="/blogs/innovations/top-5-turnkey-interior-design-trends-of-2024"
        element={
          <Navigate
            to="/blogs/decor-ideas/top-luxury-home-interior-design-trend-takeaways-of-2024-what-to-expect-in-high-end-interiors"
            replace
          />
        }
      />
      <Route
        path="/blogs/innovations/transforming-small-spaces-tips-from-leading-interior-designers-in-lucknow"
        element={<Navigate to="/interior-designers-in-lucknow" replace />}
      />
      <Route
        path="/blogs/innovations/interior-design-company-in-delhi-ncr"
        element={
          <Navigate
            to="/blogs/decor-ideas/best-office-interior-designers-in-delhi-ncr-india"
            replace
          />
        }
        />

          </Routes>
        </Layout>
      </Suspense>
     
    </HelmetProvider>
  );
});

function App() {
  return (
    <Router>
    <SourceUrlProvider>
      <AppContent />
    </SourceUrlProvider>
    </Router>
  );
}

export default App;
