// src/Store/store.js

import { configureStore } from '@reduxjs/toolkit';
import serviceReducer from "./serviceSlice";
import isThankReducer from "./isThankSlice";
import isPrivacyReducer from "./privacyslice";
import isTermsReducer from "./termSlice"
import careerDetailReducer from './careerDetailSlice'; // Import the career detail slice
const store = configureStore({
  reducer: {
    service: serviceReducer,
    careerDetail: careerDetailReducer, 
    isThank:isThankReducer,
    privacyPolicy:isPrivacyReducer,
    terms:isTermsReducer

     // Add the service slice to the Redux store
  },
});

export default store;
