

import { createSlice } from '@reduxjs/toolkit';

// Initial state for the service page
const initialState = {
    isCareerDetailPage: false,
 
};

const careerDetailSlice = createSlice({
  name: 'careerDetail',
  initialState,
  reducers: {
    setIsCareerDetailPage(state, action) {
      state.isCareerDetailPage = action.payload;
    },
  
  },
});

// Export actions for dispatching
export const { setIsCareerDetailPage } = careerDetailSlice.actions;

// Export the reducer to be added to the store
export default careerDetailSlice.reducer;
