import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import WOW from 'wowjs';

const ScrollToTop = () => {
  const { pathname } = useLocation();

  useEffect(() => {
    window.history.scrollRestoration = 'manual';
    window.scrollTo({top:0, left:0, behavior: 'instant'});
    new WOW.WOW({
        live: false
      }).init();

  }, [pathname]);



  return null;
};

export default ScrollToTop;