// src/Store/serviceSlice.js

import { createSlice } from '@reduxjs/toolkit';

// Initial state for the service page
const initialState = {
  isServiceDetailPage: false,
  isServiceMobile: false,
  nameStickyService: false,
};

const serviceSlice = createSlice({
  name: 'service',
  initialState,
  reducers: {
    setServiceDetailPage(state, action) {
      state.isServiceDetailPage = action.payload;
    },
    setServiceMobile(state, action) {
      state.isServiceMobile = action.payload;
    },
    setNameStickyService(state, action) {
      state.nameStickyService = action.payload;
    },
  },
});

// Export actions for dispatching
export const { setServiceDetailPage, setServiceMobile, setNameStickyService } = serviceSlice.actions;

// Export the reducer to be added to the store
export default serviceSlice.reducer;
