import { createSlice } from '@reduxjs/toolkit';

// Initial state for the service page
const initialState = {
    isTermPage: false,
 
};

const TermSlice = createSlice({
  name: 'terms',
  initialState,
  reducers: {
    setIsTermPage(state, action) {
      state.isTermPage = action.payload;
    },
  
  },
});

// Export actions for dispatching
export const { setIsTermPage } = TermSlice.actions;

// Export the reducer to be added to the store
export default TermSlice.reducer;